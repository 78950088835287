// **---------------------------------------------------**
// -------------------------------------------------------
// Global variables BEGIN

// store the window.scrollY value
var scrolled_y = 0;

// requestAnimationFrame
var ticking = false;

// google map init
var mapLoaded = false;

// ajax
var firstLoadDelay = 500;

// mobilenav
var header = document.getElementsByTagName('header')[0];
var hamburger = document.getElementsByClassName('MenuToggler')[0];
// var mobileNav = document.getElementsByClassName('container-main-header')[0];
// var header = document.getElementsByClassName('mobile-header')[0];
// Scrollbar
var Scrollbar;

// cache some stuff
var theBody = document.getElementsByTagName('body')[0];

// screen size
var screenWidth = window.innerWidth;
var screenHeight = window.innerHeight;

var CurLang;

// Global variables END
// **---------------------------------------------------**
// -------------------------------------------------------



// ---------------------------
// BEGIN - getParents()

	var getParents = function (elem, selector) {

	// Set up a parent array
	var parents = [];

	// Push each parent element to the array
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if (selector) {
			if (elem.matches(selector)) {
				parents.push(elem);
			}
			continue;
		}
		parents.push(elem);
	}

	// Return our parent array
	return parents;

};


// END - getParents()
// ---------------------------



// NAv Mobile START
// **---------------------------------------------------**
// -------------------------------------------------------

// function addMobileNavTrigger() {

function changeColorScroll(scrolled_y) {
	var scrollDistance = 200;
	if (scrolled_y > scrollDistance) {
		if(!header.classList.contains('is-scrolled')) {
			header.classList.add('is-scrolled');
		}
	} else {
		if(header.classList.contains('is-scrolled')) {
			header.classList.remove('is-scrolled');
		}
	}
}


function addEventTo_navMobile() {
	hamburger.addEventListener('click', function() {
		toggleMobile_nav();
	});
}

function closeMobile_nav() {
	h = hamburger;
	menu = h.children[0];
	h.classList.remove('is-active');
	header.classList.remove('is-active');
}

function toggleMobile_nav() {
	h = hamburger;
	menu = h.children[0];

	if(h.classList.contains('is-active')) {
		h.classList.remove('is-active');
		header.classList.remove('is-active');
	} else {
		h.classList.add('is-active');
		header.classList.add('is-active');
	}
}


// Remember
function joss(msg) {
	console.log(msg);
}

// ** This function is used only when the site FIRST load to have a custom animation (only use when ajax) ** //
function initFirstPageLoad() {
	if (theBody.classList.contains('js-firstLoad')) {
		setTimeout(function(){ 
			theBody.classList.remove('js-firstLoad');
		}, firstLoadDelay);

		setTimeout(function() {
			ScrollItem.prototype.watch();
		}, firstLoadDelay + 150);
	}
}

// ** Load Google font(s) async ** //
function loadFonts() {
	WebFontConfig = {
		google: {
			families: [
				'Bevan',
				'Bree+Serif',
				'Open+Sans:300i,400,400i,600,700,700i'
			]
		}
	};

	var wf = document.createElement('script');
	wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
	wf.type = 'text/javascript';
	wf.async = 'true';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(wf, s);
}

function loadStyle() {
	var style = document.createElement("link");
    style.setAttribute("rel", "stylesheet");
    style.setAttribute("type", "text/css");
    style.setAttribute("href", "/assets/stylesheets/compiled/main.css");

    document.getElementsByTagName("head")[0].appendChild(style);
}

// ** Load script async ** //
function loadScript() {
	// if you need to init some variable(s) before, they go here
	// var API_nice||{};

	var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
	s1.async = true;
	s1.src = 'yourScriptFileGoesHere.js';
	s1.charset = 'UTF-8';
	s1.setAttribute('crossorigin', '*');
	s0.parentNode.insertBefore(s1, s0);
}

// ** Smooth scrollbar ** //
function initScrollbar() {
	elContainer = document.getElementById('js-scroll');
	Scrollbar = window.Scrollbar;
	Scrollbar.init(elContainer);
	ScrollbarState = true;
}

	function getScrollInfo(info) {
		return Scrollbar.getAll()[0].info;
	}

	function getCurrentScrollTop() {
		return Scrollbar.getAll()[0].scrollTop;
	}

	function currentlyVisible(el) {
		return Scrollbar.getAll()[0].isVisible(el);
	}

	function getCurrentOffset(axis) {
		if (axis == 'y') {
			return Scrollbar.getAll()[0].offset.y;
		}

		return Scrollbar.getAll()[0].offset.x;
	}

function ScrollTo() {
	var items = document.getElementsByClassName('js-anchor');

	if (items.length > 0) {
		for (var i = 0; i < items.length; i++) {
			var item = items[i];

			item.addEventListener('click', ScrollToTarget);
		}
	}
}

function ScrollToTarget(e) {
	e.preventDefault();
	var element = e.srcElement;
	var id = element.dataset.anchor;
	var target = document.getElementById(id);

	Scrollbar.getAll()[0].scrollIntoView(target);
}

// ** Barba stuff ** //
function getCurrentNamespace() {
	var el = document.getElementsByClassName('barba-container');
	var dataNs = el[0].dataset.namespace;

	return dataNs;
}

function setTheCurrentUrl() {
	var curUrl = Barba.Utils.getCurrentUrl();
}

function getTheCurrentUrl() {
	setTheCurrentUrl();
	return curUrl;
}

function initLangSwitch() {

	var $btnLang = document.querySelectorAll('._lang ._item');

	var $btnLangActive = document.querySelectorAll('._lang ._item.is-active')[0];
	// $btnLang = $btnLang.length ? $btnLang[0] : false;
	var $barbaContainer = document.getElementsByClassName('barba-container');
	$barbaContainer = $barbaContainer.length ? $barbaContainer[0] : false;

	if ($btnLang && $barbaContainer && $barbaContainer.dataset.doggo) {
		var currentLang = $btnLangActive.dataset.lang;
		var incomingLang = $barbaContainer.dataset.lang;
		var currentUrl = $barbaContainer.dataset.current;
		var alternateUrl = $barbaContainer.dataset.doggo;

		for (var i = $btnLang.length - 1; i >= 0; i--) {
			$btnLang[i].classList.remove('is-active');
			if($btnLang[i].dataset.lang == incomingLang) {
				$btnLang[i].classList.add('is-active');
			}

			if(incomingLang == $btnLang[i].dataset.lang) {
				$btnLang[i].href = currentUrl;
			} else {
				$btnLang[i].href = alternateUrl;
			}

		}
	}
}

function setTheCurrentLang() {
	wrapper = document.getElementsByClassName('barba-container');

	if (wrapper.length > 0) {
		var lang = wrapper[0].dataset.lang;

		var CurLang = lang;
	}
}

function getTheCurrentLang() {
	setTheCurrentLang();
	return CurLang;
}

// **---------------------------------------------------**
// -------------------------------------------------------
// DOMContentLoaded BEGIN
document.addEventListener("DOMContentLoaded", function(e) {
	// *******************************
	// Functions that get called once on document ready
	loadFonts();
	loadStyle();

	// scrollbar
	initScrollbar();

    initFormButtons();
    initJsForms();
    initSearchPagination();
    
    initPopup();
    addEventTo_navMobile();
    ScrollTo();
    // eventScroll();

	Scrollbar.getAll()[0].addListener(scrolling);
	
	// watchscroll
	ScrollItem.prototype.init();

	// page first laod animation
	initFirstPageLoad();

});
// DOMContentLoaded END
// -------------------------------------------------------
// **---------------------------------------------------**


// **---------------------------------------------------**
// -------------------------------------------------------
// on resize BEGIN
window.addEventListener('resize', function(){
	screenWidth = window.innerWidth;
	screenHeight = window.innerHeight;
}, true);
// on resize END
// -------------------------------------------------------
// **---------------------------------------------------**


// **---------------------------------------------------**
// -------------------------------------------------------
// on scroll BEGIN
window.addEventListener('scroll', function(e) {
	scrolled_y = window.pageYOffset;

	if (!ticking) {
		window.requestAnimationFrame(function() {
			// place your function(s) here
			ScrollItem.prototype.watch()

			ticking = false;
		});

		ticking = true;
	}
});

/* if you have a custom scrollbar, you need to use this function to get scroll event */
function scrolling() {
	if (ScrollbarState) {
		scrolled_y = getCurrentScrollTop();
	}

	if(!mapLoaded) {
		loadGoogleMap();

		mapLoaded = true;
	}

	if (!ticking) {
		window.requestAnimationFrame(function() {
			// place your function(s) here
			ScrollItem.prototype.watch();

			changeColorScroll(scrolled_y);


			ticking = false;
		});

		ticking = true;
	}
}
// on scroll BEGIN
// **---------------------------------------------------**
// -------------------------------------------------------