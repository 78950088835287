//variables pour site Core
var retina = window.devicePixelRatio > 1;
var hasGMap = 0;


function reenableButtons(eForm) {
    eForm = eForm || document;
    var eButtons = eForm.querySelectorAll('input[type=submit], button[type=submit]');
    eButtons.forEach(function(eButton) {
        eButton.classList.remove('disabled');
        eButton.disabled = false;
        var text = '';
        if (eButton.getAttribute('data-text')) {
            text = eButton.textContent;
            eButton.textContent = eButton.getAttribute('data-text');
            eButton.setAttribute('data-text', text);
        } else if (eButton.getAttribute('data-html')) {
            text = eButton.innerHTML;
            eButton.innerHTML = eButton.getAttribute('data-html');
            eVutton.setAttribute('data-html', text);
        }
    });
}

function sendFormAjax(eForm, callback, callbackArgs) {
    var data = new FormData(eForm);
    var req = new XMLHttpRequest();

    var action = eForm.getAttribute('action');
    if (action.indexOf('?') < 0) {
        action += '?';
    }
    action += '&lan='+getTheCurrentLang()+'&ajax';


    req.open('POST', action, true);
    req.responseType = 'json';
    req.onreadystatechange = function() {
        var response = req.response;
        if (response && typeof response == 'string') {
            try {
                response = JSON.parse(response);
            } catch (e) {}
        }
        if(req.readyState == 4 && req.status == 200) {
            if (callback) {
                if (window[callback]) {
                    callback = window[callback];
                }
                var args = [response];
                args = args.concat(callbackArgs);
                callback.apply(null, args);
            } else {
                if (response.result) {
                    if (response.replaceHtml) {
                        eForm.innerHTML = response.replaceHtml;
                    }
                }
            }
        }
    }
    req.send(data);
}

function resetFormErrors(eForm) {
    eForm.querySelectorAll('.Alert.has-error').forEach(function(eEle) {
        eEle.innerHTML = '';
    });
    eForm.querySelectorAll('.Alert').forEach(function(eEle) {
        eEle.classList.add('hide');
    });
    eForm.querySelectorAll('.js-error').forEach(function(eEle) {
        eEle.parentNode.removeChild(eEle);
    });
    eForm.querySelectorAll('.FormElement.has-error').forEach(function(eEle) {
        eEle.classList.remove('has-error');
    });
}
function displaySuccess(eForm) {
    if (typeof eForm == 'string') { eForm = document.querySelector(eForm); }
    resetFormErrors(eForm);
    var eAlert = eForm.nextSibling;
    if (!eAlert || !eAlert.classList || !eAlert.classList.contains('Alert') || !eAlert.classList.contains('has-success')) {
        eAlert = eForm.parentNode.querySelector('.Alert.has-success');
    }
    eForm.style.display = 'none';
    eAlert.classList.remove('hide');
    Scrollbar.getAll()[0].scrollIntoView(eAlert, { offsetTop : 100 });
}
function displayErrors(eForm, messages) {
    if (typeof eForm == 'string') { eForm = document.querySelector(eForm); }
    eForm = eForm || document;
    resetFormErrors(eForm);
    var focusIt = true;
    for (var name in messages) {
        if (name == 'global') {
            var eAlert = eForm.querySelector('.Alert.has-error');
            eAlert.classList.remove('hide');
            eAlert.innerHTML += messages[name];
            Scrollbar.getAll()[0].scrollIntoView(eForm, { offsetTop : 100 });
        } else {
            var eEle = eForm.querySelector('*[name=' + name + ']');
            if (eEle) {
                if (focusIt && (eEle.offsetParent !== null)) {
                    eEle.focus();
                    focusIt = false;
                }
                var eFormElement = eEle.closest('.FormElement');
                if (eFormElement) {
                    eFormElement.classList.add('has-error');
                    var eErrorMessage = document.createElement('div');
                    eErrorMessage.innerHTML = '<label for="'+ eEle.getAttribute('id') + '" class="js-error">' + messages[name] + '</label>';
                    eFormElement.parentNode.insertBefore(eErrorMessage.firstChild,eFormElement.nextSibling);
                }
            }
        }
    }
}


function initFormButtons() {
    var eForms = document.querySelectorAll('form');
    if(eForms.length) {
        eForms.forEach(function(eForm) {
            eForm.addEventListener('submit', function (e) {
                var eButtons = this.querySelectorAll('input[type=submit], button[type=submit]');
                eButtons.forEach(function(eButton) {
                    eButton.classList.add('disabled');
                    eButton.disabled = true;
                    var text = '';
                    if (eButton.getAttribute('data-text')) {
                        text = eButton.textContent;
                        eButton.textContent = eButton.getAttribute('data-text');
                        eButton.setAttribute('data-text', text);
                    } else if (eButton.getAttribute('data-html')) {
                        text = eButton.innerHTML;
                        eButton.innerHTML = eButton.getAttribute('data-html');
                        eButton.setAttribute('data-html', text);
                    }
                });
            });
        });
    }
}

function initJsForms() {
    var eForms = document.querySelectorAll('form.js-form');
    var DEBUG_FORM = false;
    if (DEBUG_FORM) {
        joss('---------------------');
        joss('initiating js forms');
        joss('---------------------');
    }
    var i = 1;
    if(eForms.length) {
        eForms.forEach(function(eForm) {
            // joss(i++);
            eForm.addEventListener('submit', function (e) {
                joss('---------------------');
                joss('submitting js forms');
                joss('---------------------');
                sendFormAjax(this, function (response) {
                    joss('---------------------');
                    joss('js forms response came back');
                    joss('---------------------');
                    if (response.toast) {
                        toast_them(response.toast);
                    }
                    if (!response.error) {
                        if (response.url) {
                            location.href = response.url;
                        } else if (response.message == 'reload') {
                            location.href = location.href;
                        } else {
                            displaySuccess(eForm);
                        }
                    } else {
                        reenableButtons(eForm);
                        if (response.message) {
                            displayErrors(eForm, { 'global': response.message });
                        } else if (response.messages) {
                            displayErrors(eForm, response.messages);
                        }
                    }
                });
                e.preventDefault();
            });
        });
    }
    
}


function loadGoogleMap() {
    var eGmap_ele = document.querySelector('.Map');
    if (eGmap_ele && !hasGMap) {
        var eScriptEle = document.createElement('script');
        eScriptEle.src = 'https://maps.googleapis.com/maps/api/js?key=' + eGmap_ele.getAttribute('data-api') + '&language=' + eGmap_ele.getAttribute('data-language') + '&callback=initMap';
        eScriptEle.type = 'text/javascript';
        eScriptEle.async = 'true';
        eScriptEle.setAttribute("id", "googleMap");
        var eScripts = document.getElementsByTagName('script')[0];
        eScripts.parentNode.insertBefore(eScriptEle, eScripts);
        hasGMap++;
    } else {
        initMap();
    }
}

var loadSearch = function(e) {
    console.log('loadsearch');
    loadSearchResults(this.href, {});
    e.preventDefault();
};


function initSearchPagination() {

    var eSearch = document.getElementById('search_pagination');
    if(eSearch){
        var eElems = eSearch.querySelectorAll('a');

        eElems.forEach(function(eElem){
            eElem.addEventListener('click',loadSearch);
            eElem.addEventListener('touchstart',loadSearch);
        });

    }


}

function loadSearchResults(url, data) {
    if (url.indexOf('?') < 0) {
        url += '?';
    }


    url += '&ajax';
    console.log('url : ' + url);
    console.log('data : ' + JSON.stringify(data));


    var request = new XMLHttpRequest();
    var eSearchform = document.getElementById('search-form');
    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.onload = function() {
        if (request.readyState === 4) {
            if (request.status === 200) {
                var eSearchResults = document.getElementById('search_results');
                var eSearchPagination = document.getElementById('search_pagination');
                var response = JSON.parse(request.responseText);

                eSearchResults.innerHTML = response.html;
                eSearchPagination.innerHTML = response.pagination;

                initSearchPagination();
                console.log('scroll');
                Scrollbar.getAll()[0].scrollIntoView(eSearchResults, { offsetTop : 500 });

            } else {

                if (request.message) {
                    displayErrors(eSearchform, { 'global': request.responseText });
                } else if (request.responseText) {
                    displayErrors(eSearchform, request.responseText);
                }
            }
            reenableButtons(eSearchform);
        }
    };

    request.onerror = function() {
        console.log(request.statusText);
        alert('Une erreur est survenue, veuillez réessayer plus tard.');
    };
    request.send(data);


}

//// Acolyte core -- TODO
function joss(msg) {
    var eBody = document.body;
    if(eBody.classList.contains("isdev")) {
        console.log(msg);
    }
}

var getCookie = function (name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
};

/**
 * Affichage des popups
 */
function closePopup(ePopup) {
    var eOverlay = document.querySelector('.Popup_overlay');
    if(eOverlay) eOverlay.parentNode.removeChild(eOverlay);
    ePopup.classList.add('is-hidden');
}
function showPopup(ePopup) {
    var popups_shown = getCookie('popups_shown');
    if (popups_shown) {
        popups_shown = JSON.parse(popups_shown);
    }
    if (popups_shown instanceof Object && !Array.isArray(popups_shown)) {
        popups_shown = Object.keys(popups_shown).map(function(k) { return popups_shown[k] });
    } else if (typeof popups_shown == 'undefined') {
        popups_shown = [];
    }
    if (parseInt(ePopup.dataset.once) && popups_shown.indexOf(ePopup.dataset.id) > -1) {
        ePopup.dataset.shown = 1;
    }
    if (parseInt(ePopup.dataset.shown)) {
        return;
    }
    var eOverlay = document.querySelector('.Popup_overlay');
    if(eOverlay)
        eOverlay.parentNode.removeChild(eOverlay);
    eOverlay = document.createElement('DIV');
    eOverlay.classList.add('Popup_overlay');

    eOverlay.addEventListener('click', function(e) {
        closePopup(ePopup);
        e.stopPropagation();
        e.preventDefault();
    });
    var popupclose = ePopup.querySelectorAll('a.Popup_close');
    popupclose.forEach(
        function (e) {
            this.addEventListener('click', function(e) {
                closePopup(ePopup);
                e.stopPropagation();
                e.preventDefault();
            });
        }
    )

    document.body.prepend(eOverlay);
    ePopup.classList.remove('is-hidden');
    ePopup.dataset.shown = 1;
    if (ePopup.dataset.once) {
        popups_shown.push(ePopup.dataset.id);
        document.cookie = 'popups_shown=' + JSON.stringify(popups_shown)+';path: /';

    }
}
var scrollTop = 0;


function initPopup(){
    var ePopups = document.querySelectorAll('.Popup');
    ePopups.forEach(
        function(e){
            var ePopup = e;
            ePopup.dataset.shown = 0;
            if (ePopup.classList.contains('scrollup')) {

                var scroll = document.getElementById('js-scroll');
                if(!scroll)
                    scroll.addEventListener('scrolling', function(e) {

                        var currentScroll = this.scrollTop;
                        if (currentScroll < scrollTop - 10) {
                            setTimeout(function() { showPopup(ePopup); }, ePopup.dataset.seconds ? parseInt(ePopup.dataset.seconds) * 1000 : 0);
                        }
                        scrollTop = currentScroll;
                    });
                else
                    Scrollbar.getAll()[0].addListener(function (e){
                        var currentScroll = getCurrentScrollTop();
                        if (currentScroll < scrollTop - 10) {

                            setTimeout(function() { showPopup(ePopup); }, ePopup.dataset.seconds ? parseInt(ePopup.dataset.seconds) * 1000 : 0);
                        }
                        scrollTop = currentScroll;
                    });

            } else {
                setTimeout(function() { showPopup(ePopup); }, ePopup.dataset.seconds ? parseInt(ePopup.dataset.seconds) * 1000 : 0);
            }
        }
    );

}


